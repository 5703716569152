@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

.shiny-effect {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  z-index: 1;
  /* background: radial-gradient(circle, rgba(178, 19, 202, 0.2), transparent); */
  /* background: #121212; */
  filter: blur(80px);
}

body {
  /* font-family: "Space Grotesk", sans-serif; */
  /* background-color: #190b1f; */
  font-family: "Montserrat", sans-serif;
  background-color: black;
  overflow-x: hidden !important;
  max-width: 100%;
}
.secondary-color{
  /* background-color: #9334E9; */
  
}
span {
  @apply text-blue-600;
}
h2 {
  @apply text-xl md:text-4xl;
}
p {
  @apply text-xl tracking-tight;
}
.bg-primary-color{
  @apply bg-blue-600
}